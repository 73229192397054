<template>
  <div class="container">
    <div class="buttons">
      <h2 class="quote">Reading aloud together is powerful, HOPEFUL, joyful, comforting. Please join in!</h2>
      <a class="noselect" id="enterlibrarybutton" href="https://zoom.us/j/97584342212?pwd=eHoyZVM4VTBrSzMyOHNqRUs2emlsZz09" target="_blank"><h1>Enter Library</h1></a>
      <label for="enterlibrarybutton">(To zoom waiting room; emails me if I'm away.)</label>
      <router-link class="noselect" id="firstvisitbutton" to='/welcome'><h1>First Visit?</h1></router-link>
      <label for="firstvisitbutton">(Register so I recognize you in the waiting room.)</label>
    </div>
    <div class="snoopy">
      <img id="snoopy" src="../assets/snoopy.jpg" alt="'Welcome to the Library' Snoopy banner">
      <label for="snoopy">Our beautiful quilt was made by <a href="https://www.suebianchi.com" target="_blank">Sue Bianchi</a>.</label>
    </div>
    <div class="librarianphoto">
      <img id="librarian" src="../assets/librarianphoto.jpg" alt="A photo of Amy with one of her favorite books, Hope in the Mail by Wendelin Van Draanen">
    </div>
    <div class="missionstatement">
      <p>Imagine a library you can visit safely from home. With a librarian who has finding skills to offer, and loves free libraries. That's what is here: one rebellious retired librarian, who resists trying times with zoom read-alouds. Join me. Librarians and indie bookstores, copy me!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Pompiere&display=swap');

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: min-content;
  grid-gap: 1rem;
  grid-column-gap: 3rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-bottom: 2rem;
}

h2.quote {
  text-align: left;
  font-size: 2rem;
  color: #222;
  margin-bottom: 4rem;
  font-family: 'Pompiere', cursive;
  font-size: 2.8rem;
}

.snoopy {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
}

label {
  margin-top: .2rem;
  align-self: center;
}

.buttons, .snoopy, .librarianphoto, .missionstatement {
  max-width: 500px;
  width: 100%;
}

img {
  object-fit: scale-down;
  max-width: 100%;
  max-height: 500px;
}

p {
  font-size: 1.4rem;
  padding: 1rem;
}

a {
  text-decoration: none;
}

h1 {
  text-align: center;
  border: 2px solid #407137;
  border-radius: 8px;
  background: #407137;
  color: white;
  width: 100%;
  margin-top: 1rem;
}

h1:hover {
  background: white;
  color: #407137;
  cursor: pointer;
}

@media (min-width: 1000px) {
  h2.quote {
    font-size: 3.5rem;
  }
  img {
    max-height: 500px;
  }
  .buttons, .librarianphoto {
    margin-left: auto;
  }
  .snoopy, .missionstatement {
    margin-right: auto;
  }
}

@media (max-width: 850px), (max-height: 800px) {
  .container {
    grid-template-columns: 1fr;
  }
  h2.quote {
    margin-bottom: 1rem;
  }
  .missionstatement {
    grid-row: 3/4;
  }
  img {
    width: auto;
    margin: auto;
    padding-top: 1rem;
  }
  .buttons, .snoopy, .librarianphoto, .missionstatement {
    margin: auto;
  }
  h1 {
    width: 100%;
    margin-top: 2rem;
  }
  #librarian {
    margin-bottom: 2rem;
  }
}
</style>
