<template>
  <div id="app">
    <TopMenu/>
    <router-view></router-view>
  </div>
</template>

<script>
import TopMenu from './components/TopMenu.vue'

export default {
  name: 'App',
  components: {
    TopMenu
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.container {
  padding: 2rem 3rem;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  /* position: absolute;
  left: 0px;
  top: 270px;
  right: 0px;
  bottom: 0px;
  overflow: auto; */
}

* {
  margin: 0px;
  padding: 0px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
