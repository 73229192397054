<template>
  <div id="top-area">
    <img alt="oak tree" src="../assets/bookbanner.jpg">
    <nav class="noselect">
      <label for="hamburger">&#9776;</label>
      <input type="checkbox" id="hamburger" v-model="menuOpen"/>
      <ul class="menu">
        <li v-bind:class="{active: this.onPage('Home')}"><router-link @click.native="menuOpen = false" to="/">Home</router-link></li>
        <li v-bind:class="{active: this.onPage('Schedule')}"><router-link @click.native="menuOpen = false" to="/schedule">Schedule</router-link></li>
        <li v-bind:class="{active: this.onPage('About')}"><router-link @click.native="menuOpen = false" to="/about">About</router-link></li>
        <li v-bind:class="{active: this.onPage('FAQ')}"><router-link @click.native="menuOpen = false" to="/faq">FAQ</router-link></li>
        <!-- <li v-bind:class="{active: this.onPage('Links')}"><router-link @click.native="menuOpen = false" to="/links">Links</router-link></li> -->
        <li v-bind:class="{active: this.onPage('Contact')}"><router-link @click.native="menuOpen = false" to="/contact">Contact</router-link></li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
    name: "SplashImage",
    methods: {
      onPage(page) {
        return this.$route.name === page;
      }
    },
    data() {
      return {
        menuOpen: false
      }
    }
}
</script>

<style scoped>
#top-area {
  display: flex;
  flex-direction: column;
}
img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}
nav input, nav label {
  display: none;
}
ul.menu {
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  background-color: #617946;
  color: #eee;
}
a {
  color: inherit;
  text-decoration: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
li {
  font-weight: bold;
  font-size: 1.2rem;
  height: 100%;
  width: 100%;
  text-align: center;
}
li:hover {
  cursor: pointer;
  background-color: #364328;
}
li.active {
  background-color: #364328;
}
@media screen and (max-width: 768px) {
  nav {
    width: 100%;
    height: 50px;
    background-color: #617946;
  }
  nav label {
    display: inline-block;
    color: #eee;
    font-size: 2rem;
    padding-left: 1rem;
  }
  nav ul.menu li {
    box-sizing: border-box;
    display: block;
    width: 100%;
  }
  nav ul.menu {
    display: none;
  }
  nav input:checked ~ ul.menu {
    display: block;
    position: sticky;
    z-index: 2;
  }
  nav input:checked ~ ul.menu li {
    background-color: #617946;
  }
  nav input:checked ~ ul.menu li:hover, nav input:checked ~ ul.menu li.active {
    background-color: #364328;
  }
}
</style>